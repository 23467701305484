import { KEY_CODE_SPACE } from '@/constants/KeyCode';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import Icon from '@/components/shared/icon/Icon';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Checkbox extends PureComponent {
  static propTypes() {
    return {
      children: PropTypes.element,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      labelElement: PropTypes.element,
      onChange: PropTypes.func,
    };
  }

  _onClickKeyDownProps = mkOnClickKeyDown({
    onClick: this.props.onChange,
    overrideKeys: [KEY_CODE_SPACE],
  });

  render() {
    const className = classNames('flex-row-vcenter checkbox', {
      'is-selected': this.props.selected,
      'is-disabled': this.props.disabled,
    });
    const label = this.props.labelElement ? (
      this.props.labelElement
    ) : (
      <label className="checkbox-label">{this.props.label}</label>
    );
    // TODO: Make the checkbox more standard by following guidelines defined after audit
    return (
      <div
        className={className}
        tabIndex={0}
        role="checkbox"
        aria-checked={this.props.selected}
        aria-labelledby={`checkbox-${label}`}
        {...(!this.props.disabled ? this._onClickKeyDownProps : {})}>
        <div className="checkbox-input flex-row-vcenter">
          <Icon className="check flex-centered" icon="check" width="12" height="12" />
        </div>
        <span id={`checkbox-${label}`}>{label}</span>
        {this.props.children}
      </div>
    );
  }
}
