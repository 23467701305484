// A type from D3 (see Histogram.xScale())

import { TODO } from './types';

export type TODO__XScale = TODO<'xScale seems to be a function with props...maybe from D3?'>;

const SliderUtil = {
  constants: {
    BUBBLE_WIDTH: 30,
    DESKTOP_HANDLE_WIDTH: 20,
    DESKTOP_OVERLAP_PADDING: 2,
    MOBILE_HANDLE_WIDTH: 30,
    MOBILE_OVERLAP_PADDING: 4,
  } as const,

  clamp(val: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, val));
  },

  getHandlePosition(val: number, xScale: TODO__XScale, width: number): number {
    return xScale(val) - (width - xScale.bandwidth()) / 2;
  },

  getScaleValForVal(val: number, xScale: TODO__XScale): number {
    let pos = xScale(val);
    while (isNaN(pos)) {
      val -= 1;
      pos = xScale(val);
    }
    return val;
  },

  getHighHandleLabel(val: number, lowVal: number, step: number): string {
    let label: string | number = val + step - 1;
    if (val === lowVal) {
      label = step > 1 ? val + '-' + (val + step - 1) : val;
    }
    return label.toString();
  },

  getBubbleWidth(step: number, width: number): number {
    return step > 1 ? width * 2 : width;
  },

  getHandleWidth(isMobile: boolean): number {
    return isMobile ? this.constants.MOBILE_HANDLE_WIDTH : this.constants.DESKTOP_HANDLE_WIDTH;
  },

  getOverlapPadding(isMobile: boolean): number {
    return isMobile
      ? this.constants.MOBILE_OVERLAP_PADDING
      : this.constants.DESKTOP_OVERLAP_PADDING;
  },
};

export default SliderUtil;
