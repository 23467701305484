import ToggleFacetContainer from './ToggleFacetContainer';

import { addHeapProps } from '@/components/util/HeapTracking';
import { HeapProps } from '@/analytics/heapUtils';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';
import FacetContainerClick from '@/analytics/events/serp/FacetContainerClick';
import S2Dispatcher from '@/utils/S2Dispatcher';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type TODO__Facet = TODO<'Facet'>;

type Props = {
  title: string;
  openByDefault?: Nullable<boolean>;
  testId: string;
  isActive?: Nullable<boolean>;
  facets: Immutable.OrderedSet<TODO__Facet>;
  collapsible: boolean;
  hideTitle?: Nullable<boolean>;
  heapProps?: Nullable<HeapProps>;
};

type State = {
  isOpen: boolean;
};

class FacetContainer extends React.PureComponent<Props, State> {
  declare context: {
    dispatcher: S2Dispatcher;
  };

  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  static defaultProps: Partial<Props> = {
    isActive: true,
    openByDefault: false,
    collapsible: true,
    hideTitle: false,
  };

  constructor(...args: [any]) {
    super(...args);
    this.state = {
      isOpen: this.props.openByDefault || false,
    };
  }

  handleContainerToggle = () => {
    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () => {
        // After the container has been toggled, track that event.
        const { title, facets } = this.props;
        // Don't pass the facets for the year facet, as it makes for gigantic events
        // https://github.com/allenai/scholar/issues/5843
        const flattenedFacets =
          title !== 'Publication Year'
            ? JSON.stringify(
                facets.map(({ value, documentCount }) => {
                  return { value, documentCount };
                })
              )
            : undefined;

        trackAnalyticsEvent(FacetContainerClick.create(title, flattenedFacets, this.state.isOpen));
      }
    );
  };

  _onClickKeyDownProps = mkOnClickKeyDown({
    onClick: this.handleContainerToggle,
  });

  render(): ReactNodeish {
    const { isOpen } = this.state;
    const { testId, hideTitle, heapProps } = this.props;

    const content = <div className="facet-content">{this.props.children}</div>;

    const className = classNames('flex-row-vcenter flex-space-between facet-toggle', {
      'is-inactive': !this.props.isActive,
    });

    return (
      <section data-test-id={testId} {...(heapProps ? addHeapProps(heapProps) : {})}>
        <div className={className} {...(this.props.collapsible ? this._onClickKeyDownProps : {})}>
          {!hideTitle && <h3 className="facet-content__header">{this.props.title}</h3>}
          {this.props.collapsible ? <ToggleFacetContainer isOpen={isOpen} /> : null}
        </div>
        {!this.props.collapsible ? content : isOpen && content}
      </section>
    );
  }
}

export default FacetContainer;
