import Histogram from './Histogram';
import RangeSlider from './RangeSlider';

import { yearsToYearRanges } from '@/models/YearBucket';
import ClickEvent from '@/analytics/models/ClickEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import FacetContainer from '@/components/shared/facets/FacetContainer';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import YearRangePresets from '@/components/shared/search/YearRangePresets';

import { List } from 'immutable';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// We render every year, as otherwise certain years can't be selected.
// @see https://github.com/allenai/scholar/issues/8426
const STEP_SIZE = 1;

function getBucketRenderers({ years, filteredYears }) {
  const hasFilteredYears = !filteredYears.isEmpty();
  const bucketRenderers = [
    {
      buckets: years,
      renderer: hasFilteredYears
        ? Histogram.bucketRenderers.unfilteredBuckets
        : Histogram.bucketRenderers.selectedBuckets,
    },
  ];
  if (hasFilteredYears) {
    bucketRenderers.push({
      buckets: filteredYears,
      renderer: Histogram.bucketRenderers.selectedBuckets,
    });
  }
  return bucketRenderers;
}

export default class YearFilter extends React.PureComponent {
  static propTypes = {
    analyticsEvent: PropTypes.string.isRequired,
    centerBucketPopover: PropTypes.bool,
    years: PropTypes.instanceOf(List).isRequired,
    filteredYears: PropTypes.instanceOf(List).isRequired,
    isMobile: PropTypes.bool.isRequired,
    filterCallback: PropTypes.func.isRequired,
    histogramWidth: PropTypes.number.isRequired,
    histogramHeight: PropTypes.number.isRequired,
    renderInFacetContainer: PropTypes.bool,
    showPresets: PropTypes.bool,
    showPresetFilterCounts: PropTypes.bool,
    renderXAxis: PropTypes.bool,
    collapsible: PropTypes.bool,
    yearBuckets: PropTypes.instanceOf(List),
    yearFilter: PropTypes.object,
    popoverLabel: PropTypes.string,
    wrapperClass: PropTypes.string,
    showPresetButtons: PropTypes.bool,
  };

  static defaultProps = {
    renderInFacetContainer: true,
    showPresets: true,
    showPresetFilterCounts: true,
    renderXAxis: false,
    collapsible: true,
  };

  constructor(...args) {
    super(...args);
    this.state = this.getInitialYearFilterState(this.props);
  }

  getYearStats(props) {
    const years = props.yearBuckets ? props.yearBuckets : props.years;

    if (years && years.size > 1) {
      const yearBuckets = yearsToYearRanges(years, STEP_SIZE);
      const filteredYearBuckets = yearsToYearRanges(
        props.filteredYears,
        STEP_SIZE,
        yearBuckets.first().startKey
      );

      const filteredMin = !filteredYearBuckets.isEmpty()
        ? filteredYearBuckets.first().startKey
        : null;
      const filteredMax = !filteredYearBuckets.isEmpty() ? filteredYearBuckets.last().endKey : null;

      const min = yearBuckets.first().startKey;
      const max = yearBuckets.last().endKey;

      const rawStartYear = props.yearFilter.get('min');
      const rawEndYear = props.yearFilter.get('max');

      const resolvedStartYear = rawStartYear > max ? min : Math.max(min, rawStartYear);
      const resolvedEndYear = rawEndYear < min ? max : Math.min(max, rawEndYear);

      return {
        years: yearBuckets,
        filteredYears: filteredYearBuckets,
        min,
        max,
        filteredMin,
        filteredMax,
        selectedStartYear: resolvedStartYear,
        selectedEndYear: resolvedEndYear,
        step: STEP_SIZE,
      };
    } else {
      return null;
    }
  }

  getInitialYearFilterState(props) {
    const initialState = {
      yearSliderHighVal: null,
      yearSliderLowVal: null,
    };

    const yearStats = this.getYearStats(props);
    if (yearStats) {
      initialState.yearSliderLowVal = Math.max(
        yearStats.selectedStartYear || yearStats.min,
        yearStats.filteredMin || Number.MIN_VALUE
      );
      initialState.yearSliderHighVal = Math.min(
        yearStats.selectedEndYear || yearStats.max,
        yearStats.filteredMax || Number.MAX_VALUE
      );
    }
    return initialState;
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.getInitialYearFilterState(nextProps));
  }

  onYearFilterChange = (minYear, maxYear) => {
    trackAnalyticsEvent(ClickEvent.create(this.props.analyticsEvent, { minYear, maxYear }));
    this.props.filterCallback(minYear, maxYear);
  };

  onYearFilterMove = (min, max) => {
    this.setState({
      yearSliderLowVal: min,
      yearSliderHighVal: max,
    });
  };

  onYearBucketSelect = b => {
    const yearStats = this.getYearStats(this.props);
    if (
      (!yearStats.filteredMin || b.startKey >= yearStats.filteredMin) &&
      (!yearStats.filteredMax || b.endKey <= yearStats.filteredMax)
    ) {
      trackAnalyticsEvent(
        ClickEvent.create(EventTarget.Serp.YEAR_BUCKET, {
          minYear: b.startKey,
          maxYear: b.endKey,
        })
      );
      this.props.filterCallback(b.startKey, b.endKey);
    }
  };

  filterPopoverCount = bucket => {
    const yearStats = this.getYearStats(this.props);
    const hasFilteredYears =
      yearStats.filteredYears ||
      bucket.selectedStartYear < (yearStats.selectedStartYear || yearStats.min) ||
      bucket.selectedEndYear > (yearStats.selectedEndYear || yearStats.max) ||
      new List();

    const filteredBucket = hasFilteredYears.find(b => b.startKey === bucket.startKey);
    const filteredCount = (filteredBucket && filteredBucket.count) || 0;
    const paperCount = hasFilteredYears ? filteredCount : bucket.count;
    return paperCount;
  };

  renderHistogram(yearStats) {
    return (
      <Histogram
        id="filters"
        width={this.props.histogramWidth}
        height={this.props.histogramHeight}
        useLogScale={true}
        bucketRenderers={getBucketRenderers(yearStats)}
        renderPopover={true}
        onBucketClick={this.onYearBucketSelect}
        filterPopoverCount={this.filterPopoverCount}
        minSelected={this.state.yearSliderLowVal}
        maxSelected={this.state.yearSliderHighVal}
        paddingBottomPx={this.props.renderXAxis ? Histogram.DefaultPaddingPx.BOTTOM : 0}
        renderAxis={this.props.renderXAxis}
        showOnlyFirstAndLastXAxisValues={this.props.renderXAxis}
        popoverLabel={this.props.popoverLabel}
        centerBucketPopover={this.props.centerBucketPopover}
      />
    );
  }

  render() {
    const yearStats = this.getYearStats(this.props);
    const { wrapperClass } = this.props;

    if (yearStats) {
      const children = (
        <div className={classnames('year-filter__histogram', wrapperClass)}>
          {this.renderHistogram(yearStats)}
          <RangeSlider
            onSliderChange={this.onYearFilterChange}
            onSliderMove={this.onYearFilterMove}
            width={this.props.histogramWidth}
            min={yearStats.min}
            max={yearStats.max}
            step={yearStats.step}
            filteredMin={yearStats.filteredMin}
            filteredMax={yearStats.filteredMax}
            lowVal={this.state.yearSliderLowVal}
            highVal={this.state.yearSliderHighVal}
            isMobile={this.props.isMobile}
          />
          {this.props.showPresets && !this.props.showPresetButtons ? (
            <YearRangePresets
              years={this.props.years}
              filteredYears={this.props.filteredYears}
              onRangeSelect={this.onYearFilterChange}
              showCounts={this.props.showPresetFilterCounts}
            />
          ) : null}
          {this.props.showPresetButtons ? (
            <YearRangePresets
              years={this.props.years}
              filteredYears={this.props.filteredYears}
              onRangeSelect={this.onYearFilterChange}
              isButton
            />
          ) : null}
        </div>
      );

      return this.props.renderInFacetContainer ? (
        <FacetContainer
          title="Publication Year"
          testId="years"
          openByDefault={true}
          collapsible={this.props.collapsible}
          facets={yearStats.years}>
          {children}
        </FacetContainer>
      ) : (
        children
      );
    } else {
      return null;
    }
  }
}
