import { ValueOf } from '@/utils/types';

import Immutable from 'immutable';

export type FilterType = ValueOf<typeof FILTER_TYPE>;
export type DropdownFilterConfig = {
  startVisible: Immutable.List<FilterType>;
  startInModal: Immutable.List<FilterType>;
  collapseToModal: Immutable.List<FilterType>;
  includeClearOnQuery: boolean;
};

const FIELD_OF_STUDY = 'FIELD_OF_STUDY';
const AUTHOR = 'AUTHOR';
const COAUTHOR = 'COAUTHOR';
const HAS_PDF = 'HAS_PDF';
const VENUE = 'VENUE';
const DATE = 'DATE';
const QUERY_TEXT = 'QUERY_TEXT';
const CITATION_INTENT = 'CITATION_INTENT';

export const FILTER_TYPE = {
  FIELD_OF_STUDY,
  AUTHOR,
  COAUTHOR,
  HAS_PDF,
  VENUE,
  DATE,
  QUERY_TEXT,
  CITATION_INTENT,
} as const;

export const SERP_CONFIG: DropdownFilterConfig = {
  startVisible: Immutable.List([FIELD_OF_STUDY, DATE, HAS_PDF, AUTHOR, VENUE]),
  startInModal: Immutable.List(),
  collapseToModal: Immutable.List([AUTHOR, VENUE]),
  includeClearOnQuery: false,
};

export const AHP_CONFIG: DropdownFilterConfig = {
  startVisible: Immutable.List([COAUTHOR, HAS_PDF]),
  startInModal: Immutable.List([DATE, FIELD_OF_STUDY, VENUE]),
  collapseToModal: Immutable.List([COAUTHOR, HAS_PDF]),
  includeClearOnQuery: true,
};

export const PDP_CITE_CONFIG: DropdownFilterConfig = {
  startVisible: Immutable.List([DATE, CITATION_INTENT, HAS_PDF, AUTHOR]),
  startInModal: Immutable.List([VENUE, FIELD_OF_STUDY]),
  collapseToModal: Immutable.List([DATE, CITATION_INTENT, HAS_PDF, AUTHOR]),
  includeClearOnQuery: true,
};

export const VENUE_PAGE_CONFIG: DropdownFilterConfig = {
  startVisible: Immutable.List([FIELD_OF_STUDY, DATE, HAS_PDF, AUTHOR]),
  startInModal: Immutable.List(),
  collapseToModal: Immutable.List([AUTHOR]),
  includeClearOnQuery: true,
};
