import { KEY_CODE_SPACE } from '@/constants/KeyCode';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import Icon from '@/components/shared/icon/Icon';

import PropTypes from 'prop-types';
import React from 'react';

const { bool, number, string } = PropTypes;
const propTypes = {
  enabled: bool.isRequired,
  count: number.isRequired,
  icon: string,
  onClick: PropTypes.func.isRequired,
  ariaLabelledBy: string,
};

const defaultProps = {
  icon: 'check',
};

const classNames = (enabled, count) => {
  return `flex-row-vcenter checkbox ${enabled ? 'is-selected' : ''} ${
    count === 0 ? 'is-disabled' : ''
  }`;
};

const FacetCheckbox = ({ enabled, count, icon, onClick, ariaLabelledBy }) => {
  const _onClickKeyDownProps = React.useMemo(
    () =>
      mkOnClickKeyDown({
        onClick: onClick,
        overrideKeys: [KEY_CODE_SPACE],
      }),
    [onClick]
  );

  return (
    <div className={classNames(enabled, count)}>
      <div
        className={`checkbox-input flex-row-vcenter ${icon}`}
        data-test-id="facet-checkbox"
        {..._onClickKeyDownProps}
        tabIndex={0}
        role="checkbox"
        aria-labelledby={ariaLabelledBy}
        aria-checked={enabled}>
        <Icon className="check flex-centered" icon={icon} width="12" height="12" />
      </div>
    </div>
  );
};

FacetCheckbox.propTypes = propTypes;
FacetCheckbox.defaultProps = defaultProps;

export default FacetCheckbox;
