import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import EnvInfo from '@/env/EnvInfo';
import TooltipConstants from '@/constants/Tooltip';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

// TODO(codeviking): The tooltip should have a size parameter.  Right now we append the class
// tooltip--default-size in certain places to achieve this affect.  In the long term we should
// have a size parameter and set the default.

/**
 * Tooltip component that can be triggered on click or hover.
 *
 * @prop {element} tooltipContent The content of the tooltip
 * @prop {string} tooltipClassName An optional classname for the tooltip content
 * @prop {string} tooltipPosition The position of the tooltip relative to the hovered/clicked element
 * @prop {string} className An optional classname for the element wrapping the trigger
 * @prop {function} onShow Function to be executed when the tooltip is visible
 * @prop {string} interactionType Whether the tooltip should appear on click or hover
 * @prop {node} children The element that can be hovered/clicked to trigger the tooltip.
 */
export default class Tooltip extends PureComponent {
  static propTypes = {
    tooltipContent: PropTypes.node.isRequired,
    tooltipClassName: PropTypes.string,
    tooltipPosition: PropTypes.string,
    tooltipArrowClassName: PropTypes.string,
    className: PropTypes.string,
    onShow: PropTypes.func,
    interactionType: PropTypes.string,
    children: PropTypes.node,
    testId: PropTypes.string,
  };

  static defaultProps = {
    tooltipPosition: 'bottom-right',
    interactionType: TooltipConstants.InteractionTypes.HOVER,
  };

  static contextTypes = {
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
  };

  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      tooltipIsVisible: false,
    };
  }

  showTooltip = () => {
    // If the user shows, hides, then shows before the hideTooltip timeout has triggered, prevent
    // the tooltip from hiding.
    clearTimeout(this.currentTimer);

    if (!this.state.tooltipIsVisible) {
      this.currentTimer = setTimeout(() => {
        this.setState({ tooltipIsVisible: true });
      }, TooltipConstants.InteractionTiming.TOOLTIP_SHOW_DELAY);
    }
  };

  hideTooltip = () => {
    // If the user hides, shows, then hides before the hideTooltip timeout has triggered, prevent
    // the tooltip from showing.
    clearTimeout(this.currentTimer);

    if (this.state.tooltipIsVisible) {
      this.currentTimer = setTimeout(() => {
        this.setState({ tooltipIsVisible: false });
      }, TooltipConstants.InteractionTiming.TOOLTIP_SHOW_DELAY);
    }
  };

  hideTooltipOnPageClick = e => {
    const tooltip = this.refs.tooltip;
    // Don't hide when clicking inside tooltip
    if (e.target !== tooltip && !tooltip.contains(e.target)) {
      this.hideTooltip(e);
    }
  };

  toggleTooltip = e => {
    const tooltip = ReactDOM.findDOMNode(this);
    if ((!this.state.tooltipIsVisible && e && tooltip.contains(e.target)) || tooltip === e.target) {
      this.showTooltip();
    } else {
      this.hideTooltip();
    }
  };

  componentWillUnmount() {
    clearTimeout(this.currentTimer);
    if (this.listener) {
      document.removeEventListener('click', this.listener);
      this.listener = undefined;
    }
  }

  componentDidMount() {
    if (this.state.tooltipIsVisible) {
      if (this.props.interactionType === TooltipConstants.InteractionTypes.CLICK) {
        this.listener = this.hideTooltipOnPageClick;
        document.addEventListener('click', this.listener);
      }
      if (typeof this.props.onShow === 'function') {
        this.props.onShow();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.tooltipIsVisible &&
      this.state.tooltipIsVisible &&
      typeof this.props.onShow === 'function'
    ) {
      this.props.onShow();
    }
    if (
      !this.listener &&
      this.state.tooltipIsVisible &&
      this.props.interactionType === TooltipConstants.InteractionTypes.CLICK
    ) {
      this.listener = this.hideTooltipOnPageClick;
      document.addEventListener('click', this.listener);
    } else if (this.listener) {
      document.removeEventListener('click', this.listener);
      this.listener = undefined;
    }
  }

  _onClickKeyDownToggleProps = mkOnClickKeyDown({
    onClick: this.toggleTooltip,
  });

  renderTooltipContent() {
    if (this.state.tooltipIsVisible) {
      const classes = [
        'tooltip',
        `mod-tooltip-${this.props.tooltipPosition}`,
        this.props.tooltipClassName,
      ]
        .join(' ')
        .trim();

      const arrowClasses = classnames('tooltip-pointer-arrow', this.props.tooltipArrowClassName);

      return (
        <div className={classes} ref="tooltip">
          <span className="tooltip-pointer">
            <span className={arrowClasses} />
          </span>
          {this.props.tooltipContent}
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      envInfo: { isMobile },
    } = this.context;
    const { testId } = this.props;
    const classes = classnames('tooltip-parent', this.props.className, {
      'tooltip-mobile': isMobile,
    });

    // Default to a hover tooltip and show on click if it is specifically provided as a prop
    const showOnClick = this.props.interactionType === TooltipConstants.InteractionTypes.CLICK;
    const showOnHover = this.props.interactionType === TooltipConstants.InteractionTypes.HOVER;
    return (
      <div
        className={classes}
        onMouseEnter={showOnHover ? this.showTooltip : null}
        onMouseLeave={showOnHover ? this.hideTooltip : null}
        {...(showOnClick ? this._onClickKeyDownToggleProps : {})}
        data-test-id={testId}>
        {this.props.children}
        {this.renderTooltipContent()}
      </div>
    );
  }
}
