import { label } from '@/util';
import FacetCheckbox from '@/components/shared/checkbox/FacetCheckbox';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  facetValue: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  checkBoxIcon: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  displayCount: PropTypes.bool.isRequired,
};

const defaultProps = {
  checkBoxIcon: 'check',
  displayCount: true,
};

const Facet = props => {
  const { handleClick, isSelected, index, facetValue, checkBoxIcon, displayCount } = props;
  const { value, documentCount } = facetValue;

  const className = classNames('flex-row-vcenter checkbox facet-value', {
    'is-disabled': documentCount === 0,
    'is-selected': isSelected,
  });

  const checkboxLabel = displayCount ? label(value, documentCount) : value;

  return (
    <li className={className} data-test-id={`facet-row-${index}`}>
      <FacetCheckbox
        enabled={isSelected}
        count={documentCount}
        icon={checkBoxIcon}
        onClick={
          documentCount > 0 ? () => handleClick(value, isSelected, documentCount, index) : undefined
        }
        ariaLabelledBy={`checkbox-${checkboxLabel}`}
      />
      <span id={`checkbox-${checkboxLabel}`} className="checkbox-label">
        {checkboxLabel}
      </span>
    </li>
  );
};

Facet.propTypes = propTypes;
Facet.defaultProps = defaultProps;

export default Facet;
