import AnalyticsEvent from '@/analytics/models/AnalyticsEvent';
import EventTarget from '@/analytics/constants/EventTarget';

/** Class for tracking when a facet container was expanded or collapsed. */
export default class FacetContainerClick extends AnalyticsEvent {
  /**
   * @param {string} title - The title of the facet container.
   * @param {string} facets - A JSON string of all the facets with their values and document counts.
   * @param {boolean} expanded - Whether the container is being expanded or collapsed.
   */
  constructor(title: string, facets: string | undefined, expanded: boolean) {
    const action = expanded ? 'expand' : 'collapse';

    super(EventTarget.FACET_CONTAINER, { title, facets, action });
  }

  static create(title: string, facets: string | undefined, expanded: boolean): FacetContainerClick {
    return new FacetContainerClick(title, facets, expanded);
  }
}
