import { AnalyticsContext } from '@/components/library/paper/CLPaperAnalytics';
import { AnalyticsLocationContext } from '@/components/shared/withAnalyticsLocation';
import { getString } from '@/content/i18n';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import ClickEvent from '@/analytics/models/ClickEvent';
import CLPaperAbstract from '@/components/library/paper/CLPaperAbstract';
import CLRule from '@/components/library/layout/CLRule';
import EventTarget from '@/analytics/constants/EventTarget';
import HighlightedField, { LESS, MORE } from '@/components/shared/common/HighlightedField';
import PaperTldr from '@/components/shared/paper/PaperTldr';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import React, { ReactNode } from 'react';

export const DEFAULT_ABSTRACT_SIMILARITY_THRESHOLD = 90;

type TldrToggleProps = {
  paper: PaperishRecord;
  query?: Nullable<string>;
  className?: Nullable<string>;
  abstract?: ReactNode;
  tldr?: ReactNode;
};

const TldrToggle = ({ paper, query, className, abstract, tldr }: TldrToggleProps): ReactNodeish => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const analyticsLocation = React.useContext(AnalyticsLocationContext);
  const { onToggleAbstract: onToggleAbstractAnalytics } = React.useContext(AnalyticsContext);
  const timeoutRef = React.useRef<number>(null);
  const tldrToggleRef = React.useRef<HTMLDivElement>(null);
  const didMount = React.useRef(false);

  const onToggleWrap = React.useCallback(
    (expandState, event) => {
      event.stopPropagation();
      // this is the normal abstract toggle event that we want to keep sending for continuity purposes
      // @ts-expect-error AnalyticsContext needs proper types
      onToggleAbstractAnalytics({
        expandState,
      });

      // track a tldr custom event so we can differentiate toggles on rows that had tldrs to show
      if (analyticsLocation && paper.has('corpusId')) {
        const toggleTypeKey = expandState === MORE ? 'MORE' : 'LESS';
        const eventTarget = EventTarget.getIn(analyticsLocation, 'Tldr', toggleTypeKey);

        trackAnalyticsEvent(
          new ClickEvent(eventTarget, {
            // @ts-expect-error We check for corpus ID existing above.
            corpusID: paper.corpusId,
          })
        );
      }

      // now finally actually change the state
      setIsExpanded(expandState === MORE ? true : false);
    },
    [onToggleAbstractAnalytics]
  );

  // when unmounted, clear timeout
  React.useEffect(() => {
    if (didMount && didMount.current) {
      // this is added so focus isn't lost when we toggle the expand and collapse button
      if (tldrToggleRef.current != null) {
        tldrToggleRef.current.querySelector<HTMLButtonElement>('.more-toggle')?.focus();
      }
    } else {
      didMount.current = true;
    }

    return () => clearTimeout(timeoutRef.current ?? undefined);
  }, [isExpanded]);

  const onMore = React.useCallback(event => onToggleWrap(MORE, event), [onToggleWrap]);
  const onLess = React.useCallback(event => onToggleWrap(LESS, event), [onToggleWrap]);

  if (!paper.tldr) {
    // fallback to displaying the abstract
    return <CLPaperAbstract paper={paper} className={className} />;
  }

  if (
    paper.tldr.abstractSimilarityScore &&
    paper.tldr.abstractSimilarityScore > DEFAULT_ABSTRACT_SIMILARITY_THRESHOLD
  ) {
    // fallback to displaying the abstract
    return <CLPaperAbstract paper={paper} className={className} />;
  }

  return (
    <div
      ref={tldrToggleRef}
      className={classnames('tldr-abstract-replacement text-truncator', className)}>
      {tldr === true ? <PaperTldr paper={paper} query={query} /> : tldr}
      {!isExpanded && (
        <CLButton
          aria-label={getString(_ => _.textTruncator.default.extendAriaLabel)}
          label={getString(_ => _.textTruncator.default.extendLabel)}
          className="more mod-clickable more-toggle"
          onClick={onMore}
          type={TYPE.TERTIARY}
        />
      )}
      {!!isExpanded && (
        <div>
          <CLRule className="tldr-abstract__rule" />
          {abstract === true ? (
            <div>
              <div className="tldr-abstract__pill">
                <span className="tldr-abstract-replacement__pill">
                  {getString(_ => _.tldr.abstractLabel)}
                </span>
              </div>
              <HighlightedField field={paper.paperAbstract} fullTextClassName="full-abstract" />
            </div>
          ) : (
            abstract
          )}{' '}
          <CLButton
            aria-label={getString(_ => _.textTruncator.default.truncateAriaLabel)}
            label={getString(_ => _.textTruncator.default.truncateLabel)}
            className="more mod-clickable more-toggle"
            onClick={onLess}
            type={TYPE.TERTIARY}
          />
        </div>
      )}
    </div>
  );
};

TldrToggle.defaultProps = {
  abstract: true,
  tldr: true,
};

export default TldrToggle;
