import FacetContainer from './FacetContainer';

import { FacetValueRecord, getTotalDocumentCountFromFacetValues } from '@/models/FacetValue';
import { heapSearchFacet } from '@/analytics/attributes/searchFilters';
import { label, truncateText } from '@/util';
import { Nullable, ReactNodeish } from '@/utils/types';
import { QueryStores } from '@/stores/QueryStoresType';
import FacetClick from '@/analytics/events/serp/FacetClick';
import FacetShow from '@/analytics/events/serp/FacetShow';
import FilterCheckbox from '@/components/shared/checkbox/FilterCheckbox';
import QueryStore from '@/stores/QueryStore';
import Tooltip from '@/components/desktop/common/tooltip/Tooltip';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  filterType: string;
  injectQueryStore?: Nullable<QueryStores>;
  maxLabelLength?: Nullable<number>;
  filters: Immutable.OrderedSet<FacetValueRecord>;
  hasSelection?: Nullable<boolean>;
  collapsible?: Nullable<boolean>;
  displayDocCount?: Nullable<boolean>;
  title: string;
};

type State = {
  openByDefault: boolean;
};

export default class FilterList extends React.PureComponent<Props, State> {
  declare context: {
    queryStore: QueryStore;
  };

  static contextTypes = {
    queryStore: PropTypes.instanceOf(QueryStore).isRequired,
  };

  static defaultProps = {
    collapsible: true,
    maxLabelLength: -1,
    displayDocCount: true,
  };

  constructor(...args: [any]) {
    super(...args);
    const queryStore = this.getQueryStore();
    const firstEnabledFilter = this.props.filters.find(f =>
      queryStore.isFilterActive(this.props.filterType, f.value)
    );
    const openByDefault = firstEnabledFilter !== undefined;
    this.state = { openByDefault };
  }

  getQueryStore(): QueryStores {
    return this.props.injectQueryStore || this.context.queryStore;
  }

  handleFilterToggle = (selected: boolean, value, documentCount: number, index: number) => {
    trackAnalyticsEvent(
      FacetClick.create(this.props.filterType, value, documentCount, index, selected)
    );
  };

  renderFilter = (filter: FacetValueRecord, index: number): ReactNodeish => {
    const { displayDocCount, filterType, hasSelection, injectQueryStore, maxLabelLength } =
      this.props;

    const isSelected = this.getQueryStore().isFilterActive(filterType, filter.value);
    const fullDisplayValue = filter.displayValue || filter.value;
    const documentCount = filter.documentCount;
    // this result is rounded down to the nearest 10th.
    // so 2132 will be rounded down to 2130. We only
    // have 10 results per page. The number of pages needed
    // would be 2130 / 10 + one more page to handle the extra
    // 2 results. The stats that's displayed on our filter facet is
    // really just how many results could be fit into a page cleanly.
    const roundedCount =
      documentCount > 10 ? Math.floor((documentCount + 1) / 10) * 10 : documentCount;
    // If we have a selection within the filter type and this particular filter is not selected
    // we go into OR mode where we allow selection but since we can't easily know if results exist
    // a priori we allow selection of facets with potentially 0 matching documents. This is ok
    // since this is an OR operation.
    const orMode = hasSelection && !isSelected;
    const shouldDisplayDocCount = !orMode && displayDocCount;

    const displayValue =
      maxLabelLength && maxLabelLength !== -1
        ? truncateText(fullDisplayValue, maxLabelLength)
        : fullDisplayValue;

    const finalDisplayString = shouldDisplayDocCount
      ? label(displayValue, roundedCount)
      : displayValue;

    const labelText =
      displayValue === fullDisplayValue ? (
        <span>{finalDisplayString}</span>
      ) : (
        <Tooltip
          tooltipClassName="tooltip--default-size filter-list_facet-tooltip"
          tooltipContent={fullDisplayValue}
          tooltipPosition="bottom-left">
          {finalDisplayString}
        </Tooltip>
      );

    const labelElement = <label className="checkbox-label">{labelText}</label>;

    return (
      <li data-test-id={`facet-row-${index}`} className="facet-value" key={filter.value}>
        <FilterCheckbox
          injectQueryStore={injectQueryStore}
          onFilterToggled={this.handleFilterToggle}
          documentCount={documentCount} // used for analytics
          filterType={filterType}
          value={filter.value}
          orMode={orMode}
          labelElement={labelElement}
          index={index}
        />
      </li>
    );
  };

  componentDidMount(): void {
    const { filterType, filters } = this.props;
    const facetCount = filters.size;
    trackAnalyticsEvent(FacetShow.create(filterType, facetCount));
  }

  render(): ReactNodeish {
    const { filterType, filters, title } = this.props;
    const totalDocumentCount = getTotalDocumentCountFromFacetValues(filters);
    // Trying to map over a Set or OrderedSet causes an exception in Phantom as it has to shim
    // WeakMap: @see https://github.com/facebook/react/issues/3469
    // To resolve this we simply convert to a List before mapping over the entires
    const checkboxes = filters.toList().map(this.renderFilter);

    return (
      <FacetContainer
        title={title}
        testId={filterType}
        heapProps={heapSearchFacet(filterType)}
        isActive={totalDocumentCount > 0}
        facets={filters}
        openByDefault={this.state.openByDefault}
        collapsible={!!this.props.collapsible}>
        <ul className="facet-list">{checkboxes}</ul>
      </FacetContainer>
    );
  }
}
