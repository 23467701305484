import Facet from './Facet';

import { FacetValueRecordFactory } from '@/models/FacetValue';
import { heapHasPdfButton, heapHasPdfFacet } from '@/analytics/attributes/searchFilters';
import { Nullable, ReactNodeish } from '@/utils/types';
import { QueryStores } from '@/stores/QueryStoresType';
import ClickEvent from '@/analytics/models/ClickEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import queryRoutes, { TODO__RouterChildContext_router } from '@/utils/routing/query-routes';
import QueryStore from '@/stores/QueryStore';
import S2History from '@/utils/S2History';
import SearchToggle from '@/components/shared/fresh-serp/SearchToggle';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  className?: Nullable<string>;
  isToggle?: Nullable<boolean>;
  injectQueryStore?: Nullable<QueryStores>;
  disabled?: Nullable<boolean>;
  forCitations?: Nullable<boolean>;
};

type StateFromQueryStore = {
  isSelected: boolean;
  countOfPapersWithViewablePdf: number;
};

export default class HasViewablePdfFacet extends React.PureComponent<Props, StateFromQueryStore> {
  declare context: {
    history: S2History;
    queryStore: QueryStore;
    router: TODO__RouterChildContext_router;
  };

  static propTypes = {
    className: PropTypes.string,
    isToggle: PropTypes.bool,
    injectQueryStore: PropTypes.object,
    disabled: PropTypes.bool,
    forCitations: PropTypes.bool,
  };

  static contextTypes = {
    history: PropTypes.instanceOf(S2History).isRequired,
    queryStore: PropTypes.instanceOf(QueryStore).isRequired,
    router: PropTypes.object.isRequired,
  };

  constructor(...args: [any]) {
    super(...args);
    const queryStore = this.getQueryStore();

    this.state = {
      ...this.getStateFromQueryStore(),
    };

    queryStore.registerComponent(this, () => {
      this.setState(this.getStateFromQueryStore());
    });
  }

  getQueryStore(): QueryStores {
    return this.props.injectQueryStore || this.context.queryStore;
  }

  getStateFromQueryStore(): StateFromQueryStore {
    const queryStore = this.getQueryStore();
    const isSelected = queryStore.getQuery().requireViewablePdf || false;

    // For citations, the aggregation response is stored separately from the query response
    let statsResponse = null;
    // @ts-expect-error Some of the query stores don't have getAggregationResponse so we check for it
    if (this.props.forCitations && queryStore.getAggregationResponse != null) {
      // @ts-expect-error Some of the query stores don't have getAggregationResponse so we check for it
      statsResponse = queryStore.getAggregationResponse().stats;
    } else {
      statsResponse = queryStore.getQueryResponse().stats;
    }

    // @ts-expect-error -- statsResponse won't be null, but QueryStore isn't typed
    const countOfPapersWithViewablePdf = statsResponse.get('countOfPapersWithViewablePdf') || 0;

    return { isSelected, countOfPapersWithViewablePdf };
  }

  toggleHasPdfFilter = () => {
    const queryStore = this.getQueryStore();
    trackAnalyticsEvent(
      ClickEvent.create(EventTarget.FACET_HAS_PDF, {
        documentCount: this.state.countOfPapersWithViewablePdf,
        action: this.state.isSelected ? 'disable' : 'enable',
      })
    );
    queryRoutes.changeRouteForPartialQuery(
      queryStore.getIndependentQuery(),
      queryStore.getQuery().set('requireViewablePdf', !this.state.isSelected),
      this.context.history,
      this.context.router
    );
  };

  renderAsToggle(): ReactNodeish {
    const { className, disabled } = this.props;
    const { isSelected, countOfPapersWithViewablePdf } = this.state;

    return (
      <SearchToggle
        className={className}
        isSelected={isSelected}
        isDisabled={countOfPapersWithViewablePdf === 0 || !!disabled}
        text="Has PDF"
        testId="pdf-available"
        heapProps={heapHasPdfButton()}
        onToggle={this.toggleHasPdfFilter}
      />
    );
  }

  renderAsFacet(): ReactNodeish {
    const { className } = this.props;
    const { isSelected, countOfPapersWithViewablePdf } = this.state;
    const facetValue = FacetValueRecordFactory({
      value: 'Full text PDF available',
      documentCount: countOfPapersWithViewablePdf,
    });
    return (
      <ul
        className={classnames('facet-content', 'facet-content--without-container', className)}
        {...heapHasPdfFacet()}>
        <Facet
          handleClick={this.toggleHasPdfFilter}
          index={0}
          facetValue={facetValue}
          isSelected={isSelected}
        />
      </ul>
    );
  }

  render(): ReactNodeish {
    return this.props.isToggle ? this.renderAsToggle() : this.renderAsFacet();
  }
}
