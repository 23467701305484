export default {
  /* How the user interacts with the tooltip parent */
  InteractionTypes: {
    HOVER: 'HOVER',
    CLICK: 'CLICK',
  },

  InteractionTiming: {
    TOOLTIP_HIDE_DELAY: 250,
    TOOLTIP_SHOW_DELAY: 100,
  },
};
