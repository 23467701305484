import Checkbox from './Checkbox';

import QueryStore from '@/stores/QueryStore';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class FilterCheckbox extends PureComponent {
  static propTypes = {
    filterType: PropTypes.string.isRequired,
    orMode: PropTypes.bool,
    injectQueryStore: PropTypes.object,
    documentCount: PropTypes.number,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelElement: PropTypes.element,
    index: PropTypes.number,
    onFilterToggled: PropTypes.func,
    children: PropTypes.object,
  };

  static contextTypes = {
    queryStore: PropTypes.instanceOf(QueryStore).isRequired,
    router: PropTypes.object.isRequired,
  };

  constructor(...args) {
    super(...args);
    this.state = this.stateFromQueryStore();
    const queryStore = this.getQueryStore();

    queryStore.registerComponent(this, () => {
      this.setState(this.stateFromQueryStore());
    });
  }

  getQueryStore() {
    return this.props.injectQueryStore || this.context.queryStore;
  }

  stateFromQueryStore() {
    const { filterType, value, orMode } = this.props;
    const queryStore = this.getQueryStore();
    const isDisabled = queryStore.getFilterDocumentCount(filterType, value) === 0;
    const isSelected = queryStore.isFilterActive(filterType, value);
    const disabled = isDisabled && !isSelected && !orMode;

    return {
      disabled: disabled,
      selected: isSelected,
    };
  }

  toggleFacet = () => {
    const { onFilterToggled, filterType, value, documentCount, index } = this.props;
    const { router } = this.context;
    const queryStore = this.getQueryStore();

    if (typeof onFilterToggled === 'function') {
      // Note that this.state.selected does not update until all of the filter events
      // fire, so it represents the state we are leaving as onToggle is fired.
      onFilterToggled(this.state.selected, value, documentCount, index);
    }

    queryStore.routeToToggleFilter(filterType, value, router);
  };

  render() {
    return (
      <Checkbox
        onChange={this.toggleFacet}
        label={this.props.label}
        labelElement={this.props.labelElement}
        selected={this.state.selected}
        disabled={this.state.disabled}>
        {this.props.children}
      </Checkbox>
    );
  }
}
