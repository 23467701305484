export class FacetDefinition<
  TId extends string,
  TPluralId extends string,
  TDisplayName extends string,
> {
  id: TId;
  pluralId: TPluralId;
  displayName: TDisplayName;

  constructor(id: TId, pluralId: TPluralId, displayName: TDisplayName) {
    this.id = id;
    this.pluralId = pluralId;
    this.displayName = displayName;
  }
}

// When we upgrade to TS 5 we _should_ be able to move all the "as const" assertions to the class itself.
// https://www.totaltypescript.com/const-type-parameters
export const FacetType = {
  AUTHOR: new FacetDefinition('author' as const, 'authors' as const, 'Authors' as const),
  COAUTHOR: new FacetDefinition('coAuthor' as const, 'coAuthors' as const, 'Co-Authors' as const),
  VENUE: new FacetDefinition('venue' as const, 'venues' as const, 'Venues' as const),
  FIELDS_OF_STUDY: new FacetDefinition(
    'fieldOfStudy' as const,
    'fieldsOfStudy' as const,
    'Fields of Study' as const
  ),
} as const;
