import Histogram from './Histogram';

import * as util from '@/util';
import SliderUtil from '@/utils/slider-util';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

export default class RangeSlider extends PureComponent {
  static propTypes = {
    // First available year to be shown
    min: PropTypes.number.isRequired,
    // Last available year to be shown
    max: PropTypes.number.isRequired,
    // Some (displayed) years are actually two years (eg: 2014-2015). Step is the difference.
    step: PropTypes.number.isRequired,
    // First available year to be shown after a user has applied another filter
    filteredMin: PropTypes.number,
    // Last available year to be shown after a user has applied another filter
    filteredMax: PropTypes.number,
    // Width for the slider container
    width: PropTypes.number.isRequired,
    // Year where the first slider handle should be positioned
    lowVal: PropTypes.number.isRequired,
    // Year where the last slider handle should be positioned
    highVal: PropTypes.number.isRequired,
    // Function called when the years are to be updated and applied (onTouchEnd)
    onSliderChange: PropTypes.func,
    // Function called as the user drags the slider
    onSliderMove: PropTypes.func,
    isMobile: PropTypes.bool.isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      lowVal: this.props.lowVal || this.props.min,
      highVal: this.props.highVal || this.props.max,
      sliding: false,
    };

    // sliding parameters to share with touchMove/mouseMove and touchEnd/mouseUp
    this.slidingParams = {
      boundingRect: null,
      otherVal: null,
      xScale: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      lowVal: nextProps.lowVal || nextProps.min,
      highVal: nextProps.highVal || nextProps.max,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
  }

  handleDragStart(stateKey, e, callback = () => {}) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ sliding: true }, () => {
      const otherKey = stateKey === 'highVal' ? 'lowVal' : 'highVal';

      this.slidingParams.xScale = Histogram.xScale(
        this.props.min,
        this.props.max,
        this.props.step,
        this.props.width
      );
      this.slidingParams.boundingRect = ReactDOM.findDOMNode(this).getBoundingClientRect();
      this.slidingParams.otherVal = this.state[otherKey];

      callback();
    });
  }

  handleDragMove(xPos) {
    const newVal = SliderUtil.clamp(
      util.domainValForMousePos(this.slidingParams.xScale, xPos),
      this.props.filteredMin || this.props.min,
      this.props.filteredMax || this.props.max
    );

    const oldLowVal = this.state.lowVal;
    const oldHighVal = this.state.highVal;

    this.setState(
      {
        lowVal: Math.min(newVal, this.slidingParams.otherVal),
        highVal: Math.max(newVal + (this.props.step - 1), this.slidingParams.otherVal),
      },
      () => {
        if (
          this.props.onSliderMove &&
          (this.state.lowVal !== oldLowVal || this.state.highVal !== oldHighVal)
        ) {
          this.props.onSliderMove(this.state.lowVal, this.state.highVal);
        }
      }
    );
  }

  handleDragEnd(callback = () => {}) {
    this.setState({ sliding: false }, () => {
      this.props.onSliderChange(this.state.lowVal, this.state.highVal);

      callback();
    });
  }

  touchStart(stateKey, e) {
    this.handleDragStart(stateKey, e);
  }

  touchMove = e => {
    const xPos = e.changedTouches[0].pageX - this.slidingParams.boundingRect.left;
    this.handleDragMove(xPos);
  };

  touchEnd = () => {
    this.handleDragEnd();
  };

  mouseDown(stateKey, e) {
    if (e.button === 0) {
      this.handleDragStart(stateKey, e, () => {
        document.addEventListener('mousemove', this.mouseMove);
        document.addEventListener('mouseup', this.mouseUp);
      });
    }
  }

  mouseMove = e => {
    if (this.state.sliding) {
      const xPos = e.clientX - this.slidingParams.boundingRect.left;
      this.handleDragMove(xPos);
    }
  };

  mouseUp = e => {
    if (e.button === 0) {
      this.handleDragEnd(() => {
        document.removeEventListener('mousemove', this.mouseMove);
        document.removeEventListener('mouseup', this.mouseUp);
      });
    }
  };

  getSliderControlEventHandlers(side) {
    if (this.props.isMobile) {
      return {
        onTouchStart: this.touchStart.bind(this, side),
        onTouchEnd: this.touchEnd,
        onTouchMove: this.touchMove,
      };
    } else {
      return {
        onMouseDown: this.mouseDown.bind(this, side),
      };
    }
  }

  render() {
    const isMobile = this.props.isMobile;
    const xScale = Histogram.xScale(
      this.props.min,
      this.props.max,
      this.props.step,
      this.props.width
    );
    const lowVal = SliderUtil.getScaleValForVal(this.state.lowVal, xScale);
    const highVal = SliderUtil.getScaleValForVal(this.state.highVal, xScale);
    const lowLeft = SliderUtil.getHandlePosition(
      lowVal,
      xScale,
      SliderUtil.getHandleWidth(isMobile)
    );
    const highLeft = SliderUtil.getHandlePosition(
      highVal,
      xScale,
      SliderUtil.getHandleWidth(isMobile)
    );
    const barLeft = SliderUtil.getHandlePosition(
      this.props.min,
      xScale,
      SliderUtil.getHandleWidth(isMobile)
    );
    const barWidth = this.props.width - barLeft;
    const handleHighVal = SliderUtil.getHighHandleLabel(
      highVal,
      this.state.lowVal,
      this.props.step
    );

    let lowBubbleLeft = lowLeft;
    let highBubbleLeft = highLeft;
    const overlap =
      lowBubbleLeft +
      SliderUtil.getBubbleWidth(this.props.step, SliderUtil.constants.BUBBLE_WIDTH) -
      highBubbleLeft;
    if (lowLeft !== highLeft && overlap > 0 && lowVal !== highVal) {
      lowBubbleLeft -= Math.round(overlap / SliderUtil.getOverlapPadding(isMobile));
      highBubbleLeft += Math.round(overlap / SliderUtil.getOverlapPadding(isMobile));
    }

    const lowValEventHandlers = this.getSliderControlEventHandlers('lowVal');
    const highValEventHandlers = this.getSliderControlEventHandlers('highVal');

    return (
      <div className={classNames({ slider: true, sliding: this.state.sliding })}>
        <div
          className="bar"
          style={{
            left: barLeft,
            width: barWidth,
          }}>
          <div
            className="selection"
            style={{
              left: lowLeft - barLeft + SliderUtil.getHandleWidth(isMobile) / 2,
              width: highLeft - lowLeft,
            }}
          />
        </div>
        <div className="handle low" style={{ left: lowLeft }}>
          <span {...lowValEventHandlers} />
        </div>
        <div className="handle high" style={{ left: highLeft }}>
          <span {...highValEventHandlers} />
        </div>
        <div className="bubble value low" style={{ left: lowBubbleLeft }}>
          <span data-test-id="slider-low-label">{lowVal}</span>
        </div>
        <div className="bubble value high" style={{ left: highBubbleLeft }}>
          <span data-test-id="slider-high-label">{handleHighVal}</span>
        </div>
      </div>
    );
  }
}
