import { Nullable } from '@/utils/types';
import FlexItem from '@/components/shared/layout/FlexItem';

import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: Nullable<string>;
  isSelected: boolean;
  isDisabled: boolean;
  text: string;
  testId: string;
  heapProps: object;
  onToggle: () => void;
};

const SearchToggle = ({
  className,
  isSelected,
  isDisabled,
  text,
  testId,
  heapProps,
  onToggle,
}: Props) => {
  const cssClasses = classnames(
    'fresh-filters__filter_container toggle',
    isSelected ? 'selected' : 'unselected',
    className
  );
  const buttonCssClasses = classnames('button', {
    'is-selected': isSelected,
    'button--primary': isSelected,
    'button--secondary': !isSelected,
    'is-disabled': isDisabled,
  });

  return (
    <FlexItem className={cssClasses}>
      <button
        aria-pressed={isSelected}
        onClick={onToggle}
        className={buttonCssClasses}
        disabled={isDisabled}
        data-test-id={`${testId}-filter-button`}
        {...heapProps}>
        {text}
      </button>
    </FlexItem>
  );
};

export default SearchToggle;
