import AnalyticsEvent from '@/analytics/models/AnalyticsEvent';
import EventTarget from '@/analytics/constants/EventTarget';

/**
 * Class for tracking a facet was shown.
 *
 * @param {string} value - The facet category, e.g., Author or Brain Region
 * @param {number} count - The number of facets in the facet category.
 */
export default class FacetShow extends AnalyticsEvent {
  constructor(value: string, count: number) {
    super(EventTarget.FACET, { value, count });
  }

  static create(value: string, count: number): FacetShow {
    return new FacetShow(value, count);
  }
}
