import { format } from '@/util';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';

import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

function countForYears(years) {
  return years.reduce((totalCount, yearBucket) => {
    return totalCount + yearBucket.count;
  }, 0);
}

function getPresetYears(years, filteredYears, rangeBegin, rangeEnd) {
  const yearsToUse = filteredYears.isEmpty() ? years : filteredYears;

  return yearsToUse.filter(yearBucket => {
    return yearBucket.year >= rangeBegin && yearBucket.year <= rangeEnd;
  });
}

const YearRangePresetLink = ({
  years,
  filteredYears,
  onRangeSelect,
  rangeBegin,
  rangeEnd,
  text,
  showCount,
  testId,
  isButton,
}) => {
  const presetYears = getPresetYears(years, filteredYears, rangeBegin, rangeEnd);
  const count = countForYears(presetYears);
  const message = showCount && !isButton ? `${text} (${format(count)})` : text;
  const _onClickKeyDownProps = React.useMemo(
    () =>
      mkOnClickKeyDown({
        onClick: () => onRangeSelect(presetYears.first().year, presetYears.last().year),
      }),
    [presetYears, onRangeSelect]
  );

  if (count > 0 && !presetYears.isEmpty()) {
    if (isButton) {
      return (
        <button
          className="year-range-button button--secondary"
          data-test-id={`${testId}-filter-button`}
          onClick={() => onRangeSelect(presetYears.first().year, presetYears.last().year)}>
          {message}
        </button>
      );
    } else {
      return (
        <span
          className="year-range-preset clickable"
          data-test-id={`${testId}-filter-link`}
          {..._onClickKeyDownProps}>
          {message}
        </span>
      );
    }
  } else {
    if (isButton) {
      return <button className="year-range-button button--secondary disabled">{message}</button>;
    } else {
      return <span className="year-range-preset">{message}</span>;
    }
  }
};

YearRangePresetLink.propTypes = {
  years: PropTypes.instanceOf(List).isRequired,
  filteredYears: PropTypes.instanceOf(List).isRequired,
  onRangeSelect: PropTypes.func.isRequired,
  rangeBegin: PropTypes.number.isRequired,
  rangeEnd: PropTypes.number.isRequired,
  showCount: PropTypes.bool,
  text: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
};

YearRangePresetLink.defaultProps = {
  showCount: true,
};

export default YearRangePresetLink;
