import YearFilter from './YearFilter';

import EnvInfo from '@/env/EnvInfo';

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

export default class FilterByYearHistogram extends React.PureComponent {
  static contextTypes = {
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
  };

  static defaultProps = {
    histogramHeight: 50,
    histogramWidth: 300,
  };

  render() {
    const {
      analyticsEvent,
      centerBucketPopover,
      filters,
      filterCallback,
      histogramWidth,
      histogramHeight,
      yearBuckets,
      yearFilter,
      popoverLabel,
      wrapperClass,
    } = this.props;
    const years = yearBuckets ? yearBuckets : filters.get('years');
    return (
      <YearFilter
        analyticsEvent={analyticsEvent}
        centerBucketPopover={centerBucketPopover}
        years={years}
        filterCallback={filterCallback}
        filteredYears={filters.get('filteredYears') || new Immutable.List()}
        isMobile={this.context.envInfo.isMobile}
        histogramWidth={histogramWidth}
        histogramHeight={histogramHeight}
        renderXAxis={false}
        showPresets={false}
        renderInFacetContainer={false}
        yearBuckets={yearBuckets}
        yearFilter={yearFilter}
        popoverLabel={popoverLabel}
        wrapperClass={wrapperClass}
        showPresetButtons={this.props.showPresetButtons}
      />
    );
  }
}
