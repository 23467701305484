import Icon from '@/components/shared/icon/Icon';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ToggleFacetContainer = ({ isOpen }) => {
  const className = classNames('facet-toggle__icon', { open: isOpen });
  return (
    <div className={className}>
      <Icon icon="disclosure" width="14" height="14" />
    </div>
  );
};

ToggleFacetContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ToggleFacetContainer;
