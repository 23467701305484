import AnalyticsEvent from '@/analytics/models/AnalyticsEvent';
import EventTarget from '@/analytics/constants/EventTarget';

/**
 * Class for tracking when a facet was enabled or disabled.
 *
 * @param {string} facetType - Which facet type the facet belongs to.
 * @param {string} facetValue - The value of the facet that was clicked.
 * @param {number} documentCount - The document count of the facet clicked.
 * @param {number} index - The document count of the facet clicked.
 * @param {string} isCurrentlyEnabled - Whether the clicked facet is enabled or not.
 */
export default class FacetClick extends AnalyticsEvent {
  // eslint-disable-next-line max-params
  constructor(
    facetType: string,
    facetValue: string,
    documentCount: number,
    index: number,
    isCurrentlyEnabled: boolean
  ) {
    const action = isCurrentlyEnabled ? 'disable' : 'enable';
    super(EventTarget.FACET, { action, facetType, facetValue, documentCount, index });
  }

  // eslint-disable-next-line max-params
  static create(
    facetType: string,
    facetValue: string,
    documentCount: number,
    index: number,
    isCurrentlyEnabled: boolean
  ): FacetClick {
    return new FacetClick(facetType, facetValue, documentCount, index, isCurrentlyEnabled);
  }
}
