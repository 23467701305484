import { AnalyticsLocationContext } from '@/components/shared/withAnalyticsLocation';
import { getString } from '@/content/i18n';
import { highlightText } from '@/models/HighlightedField';
import { ModalId } from '@/constants/Modal';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import { showModal } from '@/actions/ModalActionCreators';
import { useAppContext } from '@/AppContext';
import CLButton from '@/components/library/button/CLButton';
import CLDropdown from '@/components/library/dropdown/CLDropdown';
import CLTextButton from '@/components/library/button/CLTextButton';
import EventTarget from '@/analytics/constants/EventTarget';
import HighlightedField from '@/components/shared/common/HighlightedField';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import React from 'react';

type PaperTldrProps = {
  paper: PaperishRecord;
  query?: Nullable<string>;
};

const PaperTldr = ({ paper, query }: PaperTldrProps): ReactNodeish => {
  const highlightedTldr = highlightText({
    query: query || '',
    text: (paper.tldr && paper.tldr.text) || '',
  });

  return (
    <React.Fragment>
      <CLDropdown
        className="tldr-abstract__pill"
        contents={() => <DropDownContents paper={paper} />}>
        <CLButton
          className="tldr-abstract-replacement__pill mod-clickable"
          label={getString(_ => _.tldr.tldrLabel)}
        />
      </CLDropdown>
      <HighlightedField field={highlightedTldr} expandable={false} />
    </React.Fragment>
  );
};

const DropDownContents = ({ paper }) => {
  const { dispatcher } = useAppContext();
  const analyticsLocation = React.useContext(AnalyticsLocationContext);

  const onClickContact = React.useCallback(
    event => {
      event.preventDefault();
      dispatcher.dispatch(showModal({ id: ModalId.FEEDBACK }));
    },
    [dispatcher]
  );

  // record impression
  React.useEffect(() => {
    if (analyticsLocation) {
      const eventTarget = EventTarget.getIn(analyticsLocation, 'Tldr', 'DROPDOWN');
      trackAnalyticsEvent(new ShowEvent(eventTarget, { corpusId: paper.corpusId }));
    }
  }, [analyticsLocation]);

  return (
    <div className="tldr-abstract__popover">
      <p className="tldr-abstract__popover__description">
        {getString(_ => _.tldr.tooltip.description)}
      </p>
      <hr className="tldr-abstract__popover__line" />
      <p className="tldr-abstract__popover__feedback">
        {getString(_ => _.tldr.tooltip.feedback)}
        <CLTextButton
          label={getString(_ => _.tldr.tooltip.contactUsLink)}
          onClick={onClickContact}
        />
        .
      </p>
    </div>
  );
};

export default PaperTldr;
