import YearRangePresetLink from './YearRangePresetLink';

import { getString } from '@/content/i18n';
import { heapYearRange } from '@/analytics/attributes/searchFilters';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';

import React from 'react';

type TODO__Year = TODO<'Year'>;

type Props = {
  years: Immutable.List<TODO__Year>;
  filteredYears: Immutable.List<TODO__Year>;
  onRangeSelect: () => void;
  showCounts?: Nullable<boolean>;
  isButton?: Nullable<boolean>;
};

const YearRangePresets = ({
  years,
  filteredYears,
  onRangeSelect,
  showCounts,
  isButton,
}: Props): ReactNodeish => {
  const date = new Date();
  const currentYear = date.getUTCFullYear();
  // Include last year if it's early in the current year
  const lastYear = date.getUTCMonth() > 6 ? currentYear : currentYear - 1;
  const fiveYearsAgo = currentYear - 5;
  const tenYearsAgo = currentYear - 10;

  return (
    <ul className={'year-range-presets' + (isButton ? ' buttons' : '')} {...heapYearRange()}>
      <li>
        <YearRangePresetLink
          years={years}
          filteredYears={filteredYears}
          onRangeSelect={onRangeSelect}
          rangeBegin={lastYear}
          rangeEnd={currentYear}
          showCount={showCounts}
          text={getString(_ => _.serp.histogram.button.thisYear.title)}
          testId="this-year"
          isButton={isButton}
        />
      </li>
      <li>
        <YearRangePresetLink
          years={years}
          filteredYears={filteredYears}
          onRangeSelect={onRangeSelect}
          rangeBegin={fiveYearsAgo}
          rangeEnd={currentYear}
          showCount={showCounts}
          text={getString(_ => _.serp.histogram.button.lastFive.title)}
          testId="last-five-years"
          isButton={isButton}
        />
      </li>
      <li>
        <YearRangePresetLink
          years={years}
          filteredYears={filteredYears}
          onRangeSelect={onRangeSelect}
          rangeBegin={tenYearsAgo}
          rangeEnd={currentYear}
          showCount={showCounts}
          text={getString(_ => _.serp.histogram.button.lastTen.title)}
          testId="last-ten-years"
          isButton={isButton}
        />
      </li>
    </ul>
  );
};

YearRangePresets.defaultProps = {
  showCounts: true,
  isButton: false,
};

export default YearRangePresets;
